/* eslint-disable no-case-declarations */
import { STORE_SHOWCASE_LIKE } from 'redux/actions';

const initialState = { likes: new Map() };

export default (state = initialState, action) => {
  switch (action.type) {
    case STORE_SHOWCASE_LIKE:
      const incomingShowcase = action.payload;
      state.likes.set(incomingShowcase.id, {
        count: incomingShowcase.count,
        likeId: incomingShowcase.likeId
      });
      return {
        likes: state.likes
      };

    default:
      return state;
  }
};
