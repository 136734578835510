import { CHAT_BUTTON, CHAT_BUTTON_VISIBLE } from 'redux/actions';

const initialState = { isChatOpen: false, isVisible: true };

// reducers

export default (state = initialState, action) => {
  switch (action.type) {
    case CHAT_BUTTON:
      return { ...state, isChatOpen: action.payload };
    case CHAT_BUTTON_VISIBLE:
      return { ...state, isVisible: action.payload };
    default:
      return state;
  }
};
