import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

const InjectMassage = (props) =>{
  if(props?.id){
    return <FormattedMessage {...props} />
  }
  return ""
};

export default injectIntl(InjectMassage, {
  withRef: false,
});
