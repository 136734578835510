/* eslint-disable no-empty */
import axios from 'axios';

const baseUrl = process.env.REACT_APP_SERVER_BASE_URL;
const service = 'billing';

export const getBillingDetails = async () => {
  let response = {};
  try {
    const { data } = await axios.get(`${baseUrl}/${service}/get-billing`);
    response = data.data;
  } catch (e) {
    console.error(e);
  }
  return response;
};

export const updateBillingDetails = async (payload) => {
  let response = {};
  try {
    const data = await axios.put(
      `${baseUrl}/${service}/update-billing`,
      payload
    );
    response = data.status;
  } catch (e) {
    console.error(e);
  }
  return response;
};

export const getSubscriptionDetails = async (fetchAll = false) => {
  let response = {};
  try {
    const { data } = await axios.get(`${baseUrl}/${service}/get-subscription`, {
      params: {
        fetchAll
      }
    });
    response = data.data;
  } catch (e) {
    console.error(e);
  }
  return response;
};
