import { getLocalUserRoleType } from 'helpers/Utils';
import { ROLE_TYPE } from '../actions';

const INIT_STATE = getLocalUserRoleType();

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case ROLE_TYPE:
      // eslint-disable-next-line no-case-declarations
      const role = action.payload;
      return Number.isNaN(role) ? 0 : role;
    default:
      return state;
  }
};
