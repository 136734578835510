import { initializeApp } from 'firebase/app';
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  confirmPasswordReset,
} from 'firebase/auth';
import { getDatabase } from 'firebase/database';

import { innerloopFirebaseConfig } from 'constants/defaultValues';

initializeApp(innerloopFirebaseConfig);

const auth = getAuth();
const database = getDatabase();

const getCurrentToken = async (forceRefresh = false) => {
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      unsubscribe();
      const refreshedToken = await user?.getIdToken(forceRefresh);
      resolve(refreshedToken);
    }, reject);
  });
};

export {
  auth,
  database,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  confirmPasswordReset,
  getCurrentToken,
};
