import { STORE_CATEGORY, ADD_CATEGORY } from "../actions";

const INIT_STATE = {
  data: null,
  isLoading: true,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case STORE_CATEGORY:
      return {
        data: action.payload,
        isLoading: false,
      };
    case ADD_CATEGORY:
      return {
        data: [...state.data, { label: action.payload, value: action.payload }],
        isLoading: false,
      };
    default:
      return state;
  }
};
