import { STORE_SUBSCRIPTION, UPDATE_SUBSCRIPTION } from '../actions';

// eslint-disable-next-line import/prefer-default-export
export const storeSubscription = (payload) => {
    return {
        type: STORE_SUBSCRIPTION,
        payload
    };
};

export const changeSubscriptionExpiry = (payload) => {
    return {
        type: UPDATE_SUBSCRIPTION,
        payload
    };
};