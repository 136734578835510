import { combineReducers } from 'redux';
import settings from './settings/reducer';
import menu from './menu/reducer';
import authUser from './auth/reducer';
import subscription from './subscription/reducer';
import tenantDetails from './tenantDetails/reducer';
import roleType from './roletype/reducer';
import categories from './categories/reducers';
import photon from './photon/reducer';
import stories from './stories/reducer';
import chatButton from './chatButton/reducer';
import showcases from './showcase/reducer';
import showcaseLikes from './showcaseLikes/reducer';

const reducers = combineReducers({
  menu,
  settings,
  authUser,
  subscription,
  tenantDetails,
  roleType,
  categories,
  photon,
  stories,
  chatButton,
  showcases,
  showcaseLikes
});

export default reducers;
