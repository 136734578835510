import { STORE_PHOTON } from 'redux/actions';

const INIT_STATE = null;

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case STORE_PHOTON:
      return action.payload;
    default:
      return state;
  }
};
