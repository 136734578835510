/* eslint-disable consistent-return */
import { BlobServiceClient } from '@azure/storage-blob';
import { AbortController } from '@azure/abort-controller';

const createBlobInContainer = async (
  containerClient,
  file,
  progressFn,
  callback,
  fileName
) => {
  const controller = new AbortController();
  callback(controller);
  // create blobClient for container
  const blobClient = containerClient.getBlockBlobClient(fileName);

  // set mimetype as determined from browser with file upload control
  const options = {
    blockSize: 1 * 1024 * 1024,
    onProgress: (e) => {
      progressFn(e);
    },
    abortSignal: controller.signal,
    concurrency: 2
  };

  // upload file
  try {
    await blobClient.uploadData(file, options);
    console.log('File upload successful');
    return { isError: false };
  } catch (err) {
    console.log('File upload failed');
    console.error(err);
    return { isError: true };
  }
};

const uploadFileToBlob = async (file, progressFn, callback, sasObj) => {
  const { items, sasUrl } = sasObj;
  const containerName = items[0]?.container;
  const fileName = items[0]?.fileName;

  if (!file) return [];

  // getContainerName from sasUrl
  // const azureContainerName = '22ba6f07298c4b98a061b3e1fb42a90c'; // sasUrl?.split('/')[3];

  // get BlobService = notice `?` is pulled out of sasToken - if created in Azure portal
  const blobService = new BlobServiceClient(sasUrl);

  // get Container - full public read access
  const containerClient = blobService.getContainerClient(containerName); // AZURE_CONTAINER
  // upload file
  const created = await createBlobInContainer(
    containerClient,
    file,
    progressFn,
    callback,
    fileName
  );
  return created;
};

export default uploadFileToBlob;
