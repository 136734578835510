/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  auth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  confirmPasswordReset
} from 'helpers/Firebase';
import {
  adminRoot,
  currentUser,
  loginRoute,
  registerRoute
} from 'constants/defaultValues';
import {
  setCurrentUser,
  setToken,
  handleSetupTenant,
  getCurrentUser
} from 'helpers/Utils';
import { handleCreateTenant, fetchUser } from 'functions/api';
import {
  LOGIN_USER,
  REGISTER_USER,
  LOGOUT_USER,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  REGISTER_PROFILE,
  NEW_REGISTER
} from '../actions';
import {
  loginUserSuccess,
  loginUserError,
  registerUserSuccess,
  registerUserError,
  forgotPasswordSuccess,
  forgotPasswordError,
  resetPasswordSuccess,
  resetPasswordError,
  profileRegistrationPending,
  registerProfileSuccessAction,
  registerProfileErrorAction,
  newRegisterError,
  newRegisterSuccess,
  newRegisterEmailPass
} from './actions';
import { addUserSession, deleteSession } from 'functions/api/publicApi';

export function* watchLoginUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(LOGIN_USER, loginWithEmailPassword);
}

const loginWithEmailPasswordAsync = async (email, password) =>
  // eslint-disable-next-line no-return-await
  await signInWithEmailAndPassword(auth, email, password)
    .then(async (user) => user)
    .catch((error) => error);

const fetchUserAsync = async (payload, uid, email) =>
  // eslint-disable-next-line no-return-await
  await fetchUser(payload, uid, email)
    .then((data) => data)
    .catch((error) => error);

function* afterfetchingUser({ payload }) {
  const {
    alreadyUsed,
    invitationId,
    item,
    tenantId,
    showcaseId,
    history,
    email,
    password,
    newRegisterPayload
  } = payload;
  const { data, errorType, message } = yield fetchUserAsync(
    alreadyUsed ? null : invitationId,
    item.uid,
    email
  );
  console.log({
    data,
    errorType,
    message
  });
  if (data?.data) {
    yield put(loginUserSuccess(item));
    if (tenantId) {
      history.push(`/app/showcase/${tenantId}`);
    } else if (showcaseId) {
      history.push(`/app/showcase/detail/${showcaseId}`);
    } else {
      history.push(adminRoot);
    }
  } else if (
    errorType === 'user-not-registered' ||
    data?.isError ||
    errorType === 'USER_NOT_EXIST'
  ) {
    // yield put(profileRegistrationPending());
    // yield put(loginUserSuccess(item));
    // const registerUrl = invitationId
    //   ? `${registerRoute}?id=${invitationId}`
    //   : registerRoute;
    yield call(newRegister, {
      payload: {
        ...newRegisterPayload,
        fetchUser: true
      }
    });
    // history.push(registerUrl);
  } else if (
    invitationId &&
    message ===
      'The invitation link is invalid or expired. Please contact your administrator.'
  ) {
    yield put(loginUserError('Access code not found, try to login normally'));
  } else if (message === 'TEAM_MEMBER_LIMIT_EXCEEDED') {
    yield call(loginWithEmailPassword, {
      payload: {
        user: { email, password },
        history
      }
    });
  } else if (message === 'EMAIL_SHOULD_BE_SAME_AS_INVITED_EMAIL') {
    yield put(loginUserError(message));
  } else {
    yield put(loginUserError(message));
  }
}

function* loginWithEmailPassword({ payload }) {
  const { email, password, showcaseId, tenantId } = payload.user;
  const invitationId = payload.user?.invitationId;
  const alreadyUsed = payload.user?.alreadyUsed;
  const { history, newRegisterPayload } = payload;
  try {
    const loginUser = yield call(loginWithEmailPasswordAsync, email, password);
    console.log('loginUser', loginUser);
    if (!loginUser.message) {
      const item = {
        uid: loginUser.user.uid,
        title: loginUser.user.email,
        ...currentUser
      };
      setToken(loginUser.user.accessToken);
      setCurrentUser(item);
      yield call(afterfetchingUser, {
        payload: {
          alreadyUsed,
          invitationId,
          item,
          tenantId,
          showcaseId,
          history,
          email,
          password,
          newRegisterPayload
        }
      });
    } else if (loginUser.code === 'auth/user-not-found') {
      yield put(
        loginUserError(
          'No account information found. It is not created yet or may have been deleted.'
        )
      );
    } else if (loginUser.code === 'auth/wrong-password') {
      if (alreadyUsed) {
        yield put(loginUserError('INCORRECT_PASS_USER_ALREADY_SIGNUP'));
      } else {
        yield put(loginUserError('Incorrect Password! Please Try again!'));
      }
    } else {
      yield put(loginUserError(loginUser.message));
    }
  } catch (error) {
    yield put(loginUserError(error));
  }
}

export function* watchRegisterUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(REGISTER_USER, registerWithEmailPassword);
}

// eslint-disable-next-line consistent-return
const registerWithEmailPasswordAsync = async (email, password) => {
  // eslint-disable-next-line no-return-await
  return await createUserWithEmailAndPassword(auth, email, password)
    .then((user) => user)
    .catch((error) => error);
};

const handleCreateTenantAsync = async (data) => {
  // eslint-disable-next-line no-return-await
  try {
    const res = await handleSetupTenant(data);
    return res;
  } catch (err) {
    return err;
  }
};

function* registerWithEmailPassword({ payload }) {
  console.log('payload', payload);
  const { email, password } = payload.user;
  const { history, id, showcaseId, tenantId } = payload;
  try {
    const registerUser = yield call(
      registerWithEmailPasswordAsync,
      email,
      password
    );
    if (!registerUser.message) {
      const item = {
        uid: registerUser.user.uid,
        title: email,
        ...currentUser
      };
      setCurrentUser(item);
      setToken(registerUser.user.accessToken);
      yield put(registerUserSuccess(item));
      yield put(profileRegistrationPending());
      if (id) {
        history.push(`${registerRoute}?id=${id}`);
      } else if (tenantId) {
        history.push(`${registerRoute}?tenantId=${tenantId}`);
      } else if (showcaseId) {
        history.push(`${registerRoute}?showcaseId=${showcaseId}`);
      } else {
        history.push(registerRoute);
      }
    } else if (registerUser.code === 'auth/email-already-in-use') {
      yield call(loginWithEmailPassword, {
        payload: {
          user: { email, password, invitationId: id, alreadyUsed: true },
          history
        }
      });
    } else {
      yield put(registerUserError(registerUser.message));
    }
  } catch (error) {
    yield put(registerUserError(error));
  }
}

// TO DO: Define Funtion and handle routing
// eslint-disable-next-line require-yield
function* registerProfile({ payload }) {
  try {
    const { history, id, showcaseId, tenantId } = payload;
    if (auth.currentUser) {
      const { email } = auth.currentUser;
      const { name, accessCode, country, dataRegion, industry, countryName } =
        payload.user;
      try {
        const item = { uid: auth.currentUser.uid, ...currentUser };
        const tenant = {
          name,
          email,
          accessCode: id || accessCode,
          id: auth.currentUser.uid,
          countryCode: country,
          dataRegion,
          industry: id ? 'Others' : industry,
          invitationId: id,
          country: countryName
        };
        const { data, error } = yield handleCreateTenant(
          tenant,
          auth.currentUser.accessToken
        );
        if (data) {
          localStorage.setItem(
            'tenantDetails',
            JSON.stringify({ data, isError: false })
          );
          handleCreateTenantAsync(data);
          yield put(registerProfileSuccessAction(item));
          if (tenantId) {
            history.push(`/app/showcase/${tenantId}`);
          } else if (showcaseId) {
            history.push(`/app/showcase/detail/${showcaseId}`);
          } else {
            history.push(adminRoot);
          }
        } else if (error === 'USER_WITH_ID_ALREADY_EXIST') {
          yield put(
            registerProfileSuccessAction(
              'Profile Setup Completed. Please refresh & Login Again!'
            )
          );
        } else if (error === 'INVALID_ACCESS_CODE') {
          if (id) {
            yield put(
              registerProfileErrorAction(
                'Access code not found, try to login normally'
              )
            );
          } else {
            yield put(registerProfileErrorAction('Invalid Access Code'));
          }
        } else if (error === 'TEAM_MEMBER_LIMIT_EXCEEDED') {
          yield put(
            registerProfileErrorAction(
              'Profile Setup Completed. Please refresh & Login Again!'
            )
          );
        } else {
          yield put(registerProfileErrorAction(error));
        }
      } catch (error) {
        yield put(registerProfileErrorAction(error));
      }
    } else {
      yield put(registerProfileErrorAction('User not Authenticated!'));
    }
  } catch (error) {
    console.log('error', error);
    yield put(registerProfileErrorAction('Something Went Wrong'));
  }
}

export function* watchRegisterProfile() {
  yield takeEvery(REGISTER_PROFILE, registerProfile);
}

export function* watchLogoutUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(LOGOUT_USER, logout);
}

const logoutAsync = async (history, uid) => {
  await signOut(auth)
    .then((user) => user)
    .catch((error) => error);
  deleteSession(uid);
  history?.push(adminRoot);
};

export function* logout({ payload }) {
  const { history } = payload;
  const { uid } = getCurrentUser();
  setCurrentUser();
  setToken();
  localStorage.clear();
  sessionStorage.clear();
  yield call(logoutAsync, history, uid);
}

export function* watchForgotPassword() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(FORGOT_PASSWORD, forgotPassword);
}

const forgotPasswordAsync = async (email) => {
  // eslint-disable-next-line no-return-await
  return await sendPasswordResetEmail(auth, email)
    .then((user) => user)
    .catch((error) => error);
};

function* forgotPassword({ payload }) {
  const { email } = payload.forgotUserMail;
  try {
    const forgotPasswordStatus = yield call(forgotPasswordAsync, email);
    if (!forgotPasswordStatus) {
      yield put(forgotPasswordSuccess('success'));
    } else if (forgotPasswordStatus.code === 'auth/user-not-found') {
      yield put(forgotPasswordError('User Not Registered'));
    } else {
      yield put(forgotPasswordError(forgotPasswordStatus.message));
    }
  } catch (error) {
    yield put(forgotPasswordError(error));
  }
}

export function* watchResetPassword() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(RESET_PASSWORD, resetPassword);
}

const resetPasswordAsync = async (resetPasswordCode, newPassword) => {
  // eslint-disable-next-line no-return-await
  return await confirmPasswordReset(auth, resetPasswordCode, newPassword)
    .then((user) => user)
    .catch((error) => error);
};

function* resetPassword({ payload }) {
  const { newPassword, resetPasswordCode, history } = payload;
  try {
    const resetPasswordStatus = yield call(
      resetPasswordAsync,
      resetPasswordCode,
      newPassword
    );

    if (!resetPasswordStatus) {
      yield put(resetPasswordSuccess('success'));
      history.push(loginRoute);
    } else if (resetPasswordStatus.code === 'auth/expired-action-code') {
      yield put(
        resetPasswordError(
          'Reset Link is Expired, Please Generate new Password reset Link!'
        )
      );
    } else if (resetPasswordStatus.code === 'auth/invalid-action-code') {
      yield put(
        resetPasswordError(
          'Reset Link is already used, Please Generate new Password reset Link!'
        )
      );
    } else {
      yield put(resetPasswordError(resetPasswordStatus.message));
    }
  } catch (error) {
    console.error(JSON.stringify(error));
    yield put(resetPasswordError(error));
  }
}

function* newRegister({ payload }) {
  const {
    accessCode,
    country,
    countryName,
    dataRegion,
    email,
    history,
    invitationId,
    password,
    showcaseId,
    tenantId,
    name,
    id,
    fetchUser = false
  } = payload;
  try {
    let registerUser = null;
    if (fetchUser) {
      registerUser = yield call(loginWithEmailPasswordAsync, email, password);
    } else {
      registerUser = yield call(
        registerWithEmailPasswordAsync,
        email,
        password
      );
    }
    if (!registerUser.message) {
      const item = {
        uid: registerUser.user.uid,
        title: email,
        ...currentUser
      };
      setCurrentUser(item);
      setToken(registerUser.user.accessToken);
      yield put(newRegisterEmailPass(item));
      if (auth.currentUser) {
        console.log('auth', auth);
        const { email } = auth.currentUser;
        try {
          const tenant = {
            name,
            email,
            accessCode: invitationId || accessCode,
            id: auth.currentUser.uid,
            countryCode: country,
            dataRegion,
            invitationId,
            country: countryName
          };
          const { data, error } = yield handleCreateTenant(
            tenant,
            auth.currentUser.accessToken
          );
          console.log('data', data);
          if (data) {
            localStorage.setItem(
              'tenantDetails',
              JSON.stringify({ data, isError: false })
            );
            handleCreateTenantAsync(data);
            yield addUserSession(auth.currentUser.uid, email);
            yield put(registerProfileSuccessAction());
            if (tenantId) {
              history.push(`/app/showcase/${tenantId}`);
            } else if (showcaseId) {
              history.push(`/app/showcase/detail/${showcaseId}`);
            } else {
              history.push(adminRoot);
            }
          } else if (error === 'USER_WITH_ID_ALREADY_EXIST') {
            yield put(
              newRegisterSuccess(
                'Profile Setup Completed. Please refresh & Login Again!'
              )
            );
          } else if (error === 'INVALID_ACCESS_CODE') {
            if (invitationId) {
              yield put(
                newRegisterError('Access code not found, try to login normally')
              );
            } else {
              yield put(newRegisterError('Invalid Access Code'));
            }
          } else if (error === 'TEAM_MEMBER_LIMIT_EXCEEDED') {
            yield put(
              newRegisterError(
                'Profile Setup Completed. Please refresh & Login Again!'
              )
            );
          } else {
            yield put(newRegisterError(error));
          }
        } catch (error) {
          yield put(newRegisterError(error));
        }
      } else {
        yield put(newRegisterError('User not Authenticated!'));
      }
    } else if (registerUser.code === 'auth/email-already-in-use') {
      if (invitationId) {
        yield put(newRegisterError('EMAIL_SHOULD_BE_SAME_AS_INVITED_EMAIL'));
      } else {
        yield call(loginWithEmailPassword, {
          payload: {
            user: { email, password, invitationId, alreadyUsed: true },
            history,
            newRegisterPayload: payload
          }
        });
      }
    } else {
      yield put(newRegisterError(registerUser.message));
    }
  } catch (error) {
    yield put(newRegisterError(error));
  }
}

export function* watchNewRegister() {
  yield takeEvery(NEW_REGISTER, newRegister);
}

export default function* rootSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLogoutUser),
    fork(watchRegisterUser),
    fork(watchRegisterProfile),
    fork(watchForgotPassword),
    fork(watchNewRegister),
    fork(watchResetPassword)
  ]);
}
