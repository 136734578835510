/* eslint-disable consistent-return */
/* eslint-disable no-plusplus */
/* eslint-disable no-unused-vars */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable no-else-return */
import axios from 'axios';
import { NotificationManager } from 'components/common/react-notifications';
import { getCurrentToken } from './Firebase';
import { getErrorMessage } from './errorMessages';
import IntlMessages from './IntlMessages';
import PackageJson from '../../package.json';
import { getSubscriptionDetails } from 'functions/api/billingApi';
import { getDaysUntillExpiry, handleQuotaDurationLimitToaster } from './Utils';
import { Store } from 'redux/store';
import {
  storeSubscription,
  changeSubscriptionExpiry
} from 'redux/subscription/actions';
import { storeTenantDetails } from 'redux/tenantDetails/actions';
import { storeRoleType } from 'redux/roletype/actions';

const getRetryCounts = (code) => {
  switch (code) {
    case 429:
      return 10;
    case 500:
      return 1;
    case 502:
      return 5;
    case 503:
      return 7;
    case 504:
      return 5;
    case 408:
      return 5;
    case 400:
      return 3;
    case 401:
      return 1;
    case 403:
      return 1;
    case 409:
      return 1;
    case 404:
      return 1;
    default:
      return 3;
  }
};

const dontShowUnAuthorizedNotification = [
  'get-preview-by-content-id',
  '.blob.core.windows.net/',
  'https://link.storjshare.io/',
  'update-showcase',
  'api/encode',
  'get-persoanlized-insights',
  'trigger-ai-desc'
];

const excludeMessageFromServer = [
  'SHARE_LIMIT_REACHED',
  'SOME_USER_IS_ALREADY_INVITED',
  'ONLY_USER_BELONGS_TO_TENANT_CAN_GET_TENANT',
  'USER_HAS_ASSIGNED_WORKFLOW'
];

const excludeMessageFromMiddleWare = [
  'FAILED_TO_DELETE_SESSION',
  'FAILED_TO_ADD_SESSION',
  'DUPLICATE_USER'
];

const handleErrors = async (handleErrorsProps) => {
  const { updatedConfig, retryCount, error, response, status } =
    handleErrorsProps;
  // console.log('handleErrorsProps', handleErrorsProps);
  const checkIfItsStringAndNotEmpty = (str) =>
    typeof str === 'string' && str !== '';

  if (updatedConfig.retryCount === retryCount) {
    // we often sent message from middleware side
    // with status 403.
    const messageSentFromMiddleWare = error?.response?.data;

    // message sent from server
    const messageSentFromServer = error?.response?.data?.data?.message;

    if (
      messageSentFromMiddleWare?.message ===
      'Your Subscription has been expired!'
    ) {
      Store.dispatch(changeSubscriptionExpiry(true));
      if (updatedConfig.url.includes('get-user-role')) {
        NotificationManager.error(messageSentFromMiddleWare?.message);
      }
      return response;
    }

    // console.log({
    //   messageSentFromMiddleWare,
    //   messageSentFromServer,
    //   response: error?.response
    // });

    // check if limit messages
    const isMediaSizeExceeds =
      messageSentFromServer === 'CONTENT_SIZE_LIMIT_EXCEEDED';
    const isMediaDurationExceeds =
      messageSentFromServer === 'CONTENT_DURATION_LIMIT_EXCEEDED';

    if (isMediaSizeExceeds || isMediaDurationExceeds) {
      const result = await getSubscriptionDetails();
      handleQuotaDurationLimitToaster({
        subscriptionDetails: result,
        isDuration: isMediaDurationExceeds
      });
      return response;
    }

    const dontShowToastersWhileRegisteringInvitedUsers =
      !updatedConfig.url.includes('user/on-join-tenant') &&
      !window.location.search.includes('?id') &&
      !window.location.href.includes('register') &&
      !error.response.config.url.includes('/user/create-tenant');

    if (checkIfItsStringAndNotEmpty(messageSentFromMiddleWare)) {
      if (
        !excludeMessageFromMiddleWare.includes(messageSentFromMiddleWare) &&
        !error.response.config.url.includes(
          '/check-ai-desc-status?isInitially=true'
        )
      ) {
        NotificationManager.error(
          messageSentFromMiddleWare,
          <IntlMessages id="it.failed" />,
          10000,
          null,
          null,
          ''
        );
      }
      return response;
    } else if (checkIfItsStringAndNotEmpty(messageSentFromServer)) {
      if (
        (status !== 404 && !window.location.search.includes('?id')) ||
        (!error.response.config.url.includes('/user/create-tenant') &&
          !error.response.config.url.includes('space/createUrlContent'))
      ) {
        if (
          dontShowToastersWhileRegisteringInvitedUsers &&
          !error.response.config.url.includes('get-showcase-by-id')
        ) {
          if (excludeMessageFromServer.includes(messageSentFromServer)) {
            return response;
          }
          NotificationManager.error(
            getErrorMessage(error),
            <IntlMessages id="it.failed" />,
            3000,
            null,
            null,
            ''
          );
          return response;
        }
        return response;
      }
      return response;
    } else if (
      !dontShowUnAuthorizedNotification.some((url) =>
        updatedConfig.url.includes(url)
      )
    ) {
      if (status >= 400 && status < 500) {
        NotificationManager.warning(
          <IntlMessages id="it-unauth" />,
          <IntlMessages id="it.failed" />,
          5000,
          null,
          null,
          ''
        );
        return response;
      } else {
        NotificationManager.error(
          <IntlMessages id="it-default" />,
          <IntlMessages id="it.failed" />,
          3000,
          null,
          null,
          ''
        );
        return response;
      }
    }
  }
};

function getExpiryMessage(expiryDate) {
  // const expiryDate = new Date().setDate(new Date().getDate() + 3);
  // const expiryDate = new Date().setDate(new Date().getDate() + 7);
  const isAlreadyShown = localStorage.getItem('subscription');
  // const currentDate = new Date();
  const daysUntilExpiry = getDaysUntillExpiry(expiryDate);

  if (isAlreadyShown !== '0' && daysUntilExpiry === 0) {
    localStorage.setItem('subscription', 0);
    return `The subscription is expired`;
  }

  // const timeDifference = expiryDate - currentDate;
  // const daysUntilExpiry = Math.ceil(timeDifference / (1000 * 60 * 60 * 24)) - 1;`

  if (daysUntilExpiry === 3 && isAlreadyShown !== '3') {
    localStorage.setItem('subscription', 3);
    return `The subscription will expire in 3 days`;
  } else if (daysUntilExpiry === 7 && isAlreadyShown !== '7') {
    localStorage.setItem('subscription', 7);
    return `The subscription will expire in 7 days`;
  } else {
    // cleanling the values
    localStorage.setItem('subscription', 1);
    return '';
  }
}

// eslint-disable-next-line import/prefer-default-export
export const addInterceptor = () => {
  axios.interceptors.request.use(
    async (config) => {
      if (!config.url.includes('/api/public/')) {
        // eslint-disable-next-line no-param-reassign
        config.headers = {
          ...config.headers,
          Authorization: `Bearer ${await getCurrentToken(
            config.url.endsWith('get-user')
          )}`
        };
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    async (res) => {
      try {
        const scriptTags = document.querySelectorAll('script');
        const nonce = res?.headers?.nonce;
        const configUrl = res.config.url;
        scriptTags.forEach((script) => {
          script.setAttribute('nonce', nonce);
        });
        if (configUrl.includes('get-subscription')) {
          Store.dispatch(storeSubscription(res?.data?.data));
        }
        if (configUrl.includes('/user/get-user')) {
          if (
            res?.data?.errorMsg === 'Error: Unathorised: Error decoding token'
          ) {
            NotificationManager.error(
              <IntlMessages id="Token is expired.Please login again" />
            );
            localStorage.setItem('tokenExpired', 'true');
          }
        }
        if (configUrl.includes('/user/get-user') && res?.data?.data?.fullName) {
          const roleType = res?.data?.data?.roleType;
          if (roleType) {
            Store.dispatch(storeRoleType(+roleType));
          }
          Store.dispatch(storeTenantDetails(res?.data));
        }
        if (
          configUrl.includes('/get-user-role') &&
          !configUrl.includes('/get-user-role?spaceId')
        ) {
          const role = res?.data?.data?.roleType ?? res?.data?.data;
          // console.log('role', role);
          if (role) {
            localStorage.setItem('roleType', +role);
            Store.dispatch(storeRoleType(+role));
          }
        }
        if (configUrl.includes('get-subscription')) {
          // console.log('res?.data?.data', res?.data?.data);
          const subExpired = res?.data?.data?.endDateTime;
          const formedDate = new Date(subExpired);
          // console.log({
          //   subExpired,
          //   formedDate
          // });
          if (formedDate) {
            const message = getExpiryMessage(formedDate);
            // eslint-disable-next-line no-unused-expressions
            message && NotificationManager.warning(message);
          }
        }
        const serverVerison = res?.data?.serverVersion;
        const packageVersion = PackageJson.version;
        if (serverVerison && packageVersion) {
          const refreshNeeded = serverVerison !== packageVersion;
          localStorage.setItem(
            'version',
            JSON.stringify({
              server: serverVerison,
              client: packageVersion,
              refreshNeeded
            })
          );
          return res;
        }
        return res;
      } catch (error) {
        console.log('error in interceptror response', error);
        console.log('res', res);
        return res;
      }
    },
    async (error) => {
      const { config, response } = error;
      if (response) {
        // console.log('response', response);
        const { status } = response;
        // eslint-disable-next-line no-prototype-builtins
        let retryCount = 0;
        if (
          response?.errorMsg === 'INVALID_ACCESS_CODE' ||
          response?.data?.errorMsg === 'INVALID_ACCESS_CODE'
        ) {
          retryCount = 2;
        } else if (response?.data?.data?.contentSource) {
          retryCount = 0;
          return response;
        } else if (response?.data?.data?.message === 'HIGH_DEMAND') {
          retryCount = 0;
          return response;
        } else {
          retryCount = getRetryCounts(status);
        }
        const currentRetryCount = config.retryCount || 0;
        if (status === 503 && currentRetryCount === 3) {
          NotificationManager.error(<IntlMessages id="retry-503-error" />);
        }
        if (currentRetryCount < retryCount) {
          // Increment the retry count and retry the request
          const updatedConfig = {
            ...config,
            retryCount: currentRetryCount + 1
          };
          await new Promise((resolve) =>
            // eslint-disable-next-line no-restricted-properties
            setTimeout(resolve, 1000 * Math.pow(2, currentRetryCount))
          );
          try {
            let token = '';
            if (!updatedConfig.url.includes('/api/public/')) {
              token = await getCurrentToken(
                updatedConfig.url.endsWith('get-user')
              );
            }
            updatedConfig.headers.Authorization = `Bearer ${token}`;
            const res = await axios(updatedConfig);
            if (!res) {
              return handleErrors({
                updatedConfig,
                retryCount,
                error,
                response,
                status
              });
            }
            return res;
          } catch (err) {
            return handleErrors({
              updatedConfig,
              retryCount,
              error,
              err,
              response,
              status
            });
          }
        }
      }
    }
  );
};
