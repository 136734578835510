import { getCurrentLanguage } from 'helpers/Utils';
import {
  CHANGE_LOCALE,
  FETCH_NOTIFICATIONS,
  FETCH_NOTIFICATIONS_RESPONSE
} from '../actions';

const INIT_STATE = {
  locale: getCurrentLanguage(),
  notifications: [],
  isFetchingNotification: false,
  isInitialFetching: false,
  hasMore: true,
  skipCount: 0,
  maxResultCount: 2
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CHANGE_LOCALE:
      return { ...state, locale: action.payload };

    case FETCH_NOTIFICATIONS:
      if (action.payload.skipCount === 0) {
        return {
          ...state,
          isInitialFetching: true
        };
      }
      return {
        ...state,
        isFetchingNotification: true,
        isInitialFetching: false
      };

    case FETCH_NOTIFICATIONS_RESPONSE:
      if (action?.payload) {
        if (action?.payload?.length < 2) {
          state.hasMore = false;
        }
        state.skipCount += 2;
        state.isInitialFetching = false;
        state.isFetchingNotification = false;
        state.notifications = [...state.notifications, ...action.payload];
      }
      return state;

    default:
      return { ...state };
  }
};
