// eslint-disable-next-line import/no-cycle
import {
  ROUTE_CHANGE,
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  LOGIN_USER_ERROR,
  REGISTER_USER_ERROR,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  REGISTER_PROFILE,
  REGISTER_PROFILE_SUCCESS,
  REGISTER_PROFILE_ERROR,
  PROFILE_REGISTRATION_PENDING,
  NEW_REGISTER,
  NEW_REGISTER_ERROR,
  NEW_REGISTER_SUCCESS,
  NEW_REGISTER_EMAIL_PASS_SUCCESS
} from '../actions';

export const routeChange = () => {
  return {
    type: ROUTE_CHANGE,
    payload: {}
  };
};

export const loginUser = (user, history) => ({
  type: LOGIN_USER,
  payload: { user, history }
});
export const loginUserSuccess = (user) => ({
  type: LOGIN_USER_SUCCESS,
  payload: user
});
export const loginUserError = (message) => ({
  type: LOGIN_USER_ERROR,
  payload: { message }
});

export const forgotPassword = (forgotUserMail, history) => ({
  type: FORGOT_PASSWORD,
  payload: { forgotUserMail, history }
});
export const forgotPasswordSuccess = (forgotUserMail) => ({
  type: FORGOT_PASSWORD_SUCCESS,
  payload: forgotUserMail
});
export const forgotPasswordError = (message) => ({
  type: FORGOT_PASSWORD_ERROR,
  payload: { message }
});

export const resetPassword = ({ resetPasswordCode, newPassword, history }) => ({
  type: RESET_PASSWORD,
  payload: { resetPasswordCode, newPassword, history }
});
export const resetPasswordSuccess = (newPassword) => ({
  type: RESET_PASSWORD_SUCCESS,
  payload: newPassword
});
export const resetPasswordError = (message) => ({
  type: RESET_PASSWORD_ERROR,
  payload: { message }
});

export const registerUser = (user, history, id, showcaseId) => ({
  type: REGISTER_USER,
  payload: { user, history, id, showcaseId }
});
export const registerUserSuccess = (user) => ({
  type: REGISTER_USER_SUCCESS,
  payload: user
});
export const registerUserError = (message) => ({
  type: REGISTER_USER_ERROR,
  payload: { message }
});

export const registerProfileAction = (
  user,
  history,
  id,
  showcaseId,
  tenantId
) => ({
  type: REGISTER_PROFILE,
  payload: { user, history, id, showcaseId, tenantId }
});

export const profileRegistrationPending = () => ({
  type: PROFILE_REGISTRATION_PENDING,
  payload: {}
});

export const registerProfileSuccessAction = () => ({
  type: REGISTER_PROFILE_SUCCESS,
  payload: {}
});
export const registerProfileErrorAction = (message) => ({
  type: REGISTER_PROFILE_ERROR,
  payload: { message, profileSetupRequired: true }
});

export const logoutUser = (history) => ({
  type: LOGOUT_USER,
  payload: { history }
});

export const newRegister = ({
  email,
  accessCode,
  password,
  countryName,
  dataRegion,
  country,
  history,
  invitationId,
  showcaseId,
  tenantId,
  name
}) => ({
  type: NEW_REGISTER,
  payload: {
    email,
    accessCode,
    password,
    countryName,
    dataRegion,
    country,
    history,
    invitationId,
    showcaseId,
    tenantId,
    name
  }
});

export const newRegisterError = (message) => ({
  type: NEW_REGISTER_ERROR,
  payload: { message }
});

export const newRegisterEmailPass = (user) => ({
  type: NEW_REGISTER_EMAIL_PASS_SUCCESS,
  payload: user
});

export const newRegisterSuccess = (message) => ({
  type: NEW_REGISTER_SUCCESS,
  payload: { message }
});
