import { getCurrentUser } from 'helpers/Utils';
import { isAuthGuardActive, currentUser } from 'constants/defaultValues';

import {
  ROUTE_CHANGE,
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_ERROR,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_ERROR,
  LOGOUT_USER,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  PROFILE_REGISTRATION_PENDING,
  REGISTER_PROFILE,
  REGISTER_PROFILE_SUCCESS,
  REGISTER_PROFILE_ERROR,
  NEW_REGISTER,
  NEW_REGISTER_ERROR,
  NEW_REGISTER_SUCCESS,
  NEW_REGISTER_EMAIL_PASS_SUCCESS
} from '../actions';

const INIT_STATE = {
  currentUser: isAuthGuardActive ? currentUser : getCurrentUser(),
  forgotUserMail: '',
  newPassword: '',
  resetPasswordCode: '',
  loading: false,
  error: '',
  profileSetupRequired: false
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case ROUTE_CHANGE:
      return { ...state, error: '', forgotUserMail: '' };
    case LOGIN_USER:
      return { ...state, loading: true, error: '' };
    case LOGIN_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        currentUser: action.payload,
        error: ''
      };
    case LOGIN_USER_ERROR:
      return {
        ...state,
        loading: false,
        currentUser: null,
        error: action.payload.message
      };
    case FORGOT_PASSWORD:
      return { ...state, loading: true, error: '' };
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        forgotUserMail: action.payload,
        error: ''
      };
    case FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
        forgotUserMail: '',
        error: action.payload.message
      };
    case RESET_PASSWORD:
      return { ...state, loading: true, error: '' };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        newPassword: action.payload,
        resetPasswordCode: '',
        error: ''
      };
    case RESET_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
        newPassword: '',
        resetPasswordCode: '',
        error: action.payload.message
      };
    case REGISTER_USER:
      return { ...state, loading: true, error: '' };
    case REGISTER_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        currentUser: action.payload,
        error: ''
      };
    case REGISTER_USER_ERROR:
      return {
        ...state,
        loading: false,
        currentUser: null,
        error: action.payload.message
      };
    case PROFILE_REGISTRATION_PENDING:
      return {
        ...state,
        profileSetupRequired: true,
        loading: false,
        error: ''
      };
    case REGISTER_PROFILE:
      return { ...state, loading: true, error: '' };
    case REGISTER_PROFILE_SUCCESS:
      return {
        ...state,
        profileSetupRequired: false,
        loading: false,
        error: ''
      };
    case REGISTER_PROFILE_ERROR:
      return { ...state, loading: false, error: action.payload.message };
    case LOGOUT_USER:
      return { ...state, currentUser: null, error: '' };
    case NEW_REGISTER:
      return { ...state, loading: true, error: '' };
    case NEW_REGISTER_ERROR:
      return { ...state, loading: false, error: action.payload.message };
    case NEW_REGISTER_SUCCESS:
      return { ...state, loading: false, error: '' };
    case NEW_REGISTER_EMAIL_PASS_SUCCESS:
      return {
        ...state,
        loading: true,
        currentUser: action.payload,
        error: ''
      };
    default:
      return { ...state };
  }
};
