import {TENANTS_DETAILS} from '../actions'

const INIT_STATE={
    isError:false,
    data:null
}

export default (state=INIT_STATE,action)=>{
    switch(action.type){
        case TENANTS_DETAILS:
            return action.payload
        default:
            return state
    }
}