import { setCurrentLanguage } from 'helpers/Utils';
// eslint-disable-next-line import/no-cycle
import {
  CHANGE_LOCALE,
  FETCH_NOTIFICATIONS,
  FETCH_NOTIFICATIONS_RESPONSE
} from '../actions';

// eslint-disable-next-line import/prefer-default-export
export const changeLocale = (locale) => {
  setCurrentLanguage(locale);
  return {
    type: CHANGE_LOCALE,
    payload: locale
  };
};

export const fetchNotifications = (payload) => {
  return {
    type: FETCH_NOTIFICATIONS,
    payload
  };
};

export const storeNotifications = (notifications) => {
  return {
    type: FETCH_NOTIFICATIONS_RESPONSE,
    payload: notifications
  };
};
