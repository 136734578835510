import './assets/css/vendor/bootstrap.min.css';
import './assets/css/vendor/bootstrap.rtl.only.min.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-image-lightbox/style.css';
import 'video.js/dist/video-js.css';
import 'rc-switch/assets/index.css';
import 'react-medium-image-zoom/dist/styles.css';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import 'react-tagsinput/react-tagsinput.css';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'react-loading-skeleton/dist/skeleton.css';
import * as serviceWorker from './serviceWorker';

import {
  isMultiColorActive,
  defaultColor,
  isDarkSwitchActive
} from './constants/defaultValues';
import { getCurrentColor, setCurrentColor } from './helpers/Utils';

const logNothing = () => {};

if (process.env.REACT_APP_ENV === 'PROD') {
  console.log = logNothing;
  console.error = logNothing;
  console.info = logNothing;
  console.warn = logNothing;
}

const color =
  isMultiColorActive || isDarkSwitchActive ? getCurrentColor() : defaultColor;
setCurrentColor(color);

const render = () => {
  import(`./assets/css/sass/themes/gogo.${color}.scss`).then(() => {
    // eslint-disable-next-line global-require
    require('./AppRenderer');
  });
};
render();

serviceWorker.register();
