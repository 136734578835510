import { all, call, fork, putResolve, takeEvery } from 'redux-saga/effects';
import { FETCH_NOTIFICATIONS } from 'redux/actions';

import { fetchSystemNotifications } from 'functions/api';
import { storeNotifications } from './actions';

export function* watchFetchNotifications() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(FETCH_NOTIFICATIONS, fetchNotifications);
}

export function* fetchNotifications({ payload }) {
  const response = yield call(fetchSystemNotifications, {
    skipCount: payload.skipCount,
    maxResultCount: payload.maxResultCount
  });
  yield putResolve(storeNotifications(response.data));
}

export default function* rootSaga() {
  yield all([fork(watchFetchNotifications)]);
}
